/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  FWAD base Font size
*/
/*
 *  Use Defaults
*/
/*
 * Background Image
 */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * Mobile
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/**
*   Background gradient mixin
**/
/*
 * Opacity
 */
/*
 * Box Shadow
 */
/*
 * Box Shadow
 */
/*
 * upto Tablet
 */
/*
 * Clearfix
 */
/*
 * @ Fonts
 *
*/
/*
 * H1
*/
/* Russian */
/* Chinese */
/*
 * H2
*/
/* Russian */
/* Chinese */
/*
 * H3
*/
/* Russian */
/* Chinese */
/*
/*
 * H4
*/
/* Russian */
/* Chinese */
/*
 * H5
*/
/* Russian */
/* Chinese */
/*
 * H6
*/
/* Russian */
/* Chinese */
/*
 * body-1
*/
/*
 * body-copy-2
*/
/*
 * body-3
*/
/*
 * body-copy-4
*/
/*
 *  Base Container
*/
/*
 *  Generic Colors
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 * Base
 * @ Colors
 *
*/
/* Headings */ /*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Flexbox
 *
*/
.flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

/*
* Breakpoint
* @ Variables
*
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    to be applied on page parent level
*/
/*
    wrapper for each component
    to be used to give max width to your component
*/
.component {
  margin-bottom: 40px;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .component {
    margin-bottom: 60px;
  }
}
.component .w--content {
  margin: 0 auto;
  text-align: center;
  max-width: 93.3333333333%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .component .w--content {
    max-width: 96.875%;
  }
}
@media only screen and (min-width: 1024px) {
  .component .w--content {
    max-width: 96.09375%;
  }
}
@media only screen and (min-width: 1366px) {
  .component .w--content {
    max-width: 1296px;
  }
}

@font-face {
  font-family: "icon-font";
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * Line height font
 */
.tenant-ppad {
  /* < 1023px */
}
.tenant-ppad .c-header {
  position: relative;
  background: #fff;
  height: 87px;
  border-bottom: 1px solid #efdbb2;
  /* Medium devices (desktops, 1024px and up) */
  /****** search filed *****/
  /*
  * Auto Complete Section in Header Input Box Styling
  */
  /*
  * Auto Complete Section in Header Result Styling
  */
}
.tenant-ppad .c-header.component {
  max-width: none;
  display: block;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header {
    height: 135px;
    padding: 5px 33px;
  }
}
.tenant-ppad .c-header .nav-item-search .overlay-content-container {
  overflow: visible;
}
.tenant-ppad .c-header .nav-item-search .overlay-content-container .overlay-content-inner-container {
  overflow: visible;
}
.tenant-ppad .c-header .c-header-wrapper {
  padding-left: 0;
  max-width: 1313px;
  margin: 0 auto;
}
.lang-ar .tenant-ppad .c-header .c-header-wrapper {
  padding-right: 0;
  padding-left: 0;
}
.tenant-ppad .c-header .c-header-wrapper .c-header-top-logo a:focus {
  opacity: 1;
}
.tenant-ppad .c-header .c-header-wrapper .header-body {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-wrapper .header-body {
    display: block;
  }
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom {
  display: flex;
  align-items: center;
  flex-direction: revert;
  width: 100%;
  height: 100%;
  padding-top: 31px;
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon {
  top: 0;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon::before {
  top: 0;
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon.profile::before {
  position: relative;
  top: 0;
  transform: none;
  font-size: 14px;
  left: 0;
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom .btn-subscribe {
  margin: 0;
}
.tenant-ppad .c-header .c-header-wrapper.hide-on-load .header-bottom .header-bottom-profile {
  padding: 0;
  margin: 0;
}
.tenant-ppad .c-header.sticky-header.header-pre-logged {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged {
    border: 1px solid #efdbb2;
    position: fixed;
    top: 0;
    height: 70px;
    max-width: 100%;
    padding: 12px;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .c-header-top-logo {
    margin-top: -25px;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .c-header-top-logo img {
    width: auto;
    height: 61px;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .header-body {
    margin-right: 18%;
    top: 12px;
  }
  .lang-ar .tenant-ppad .c-header.sticky-header.header-pre-logged .header-body {
    margin-left: 18%;
    margin-right: auto;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .c-header-top {
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged .c-header-top {
    padding: 10px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom {
    float: right;
    right: 0;
    margin-top: 2px;
    width: auto;
    position: absolute;
    max-height: 38px;
    padding: 14px 0;
  }
  .lang-ar .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom {
    float: left;
  }
  .lang-ar .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom {
    left: 0;
    right: auto;
    right: auto;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .c-header-top-icon,
.tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .opening-hours-dropdown, .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom-lang-switcher {
    display: none;
  }
  .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .btn-primary a {
    right: 30px;
    margin: 0;
    /* Medium devices (desktops, 1024px and up) */
  }
  .lang-ar .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .btn-primary a {
    left: 30px;
    right: auto;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .btn-primary a {
    right: 0;
  }
  .lang-ar .tenant-ppad .c-header.sticky-header.header-pre-logged .header-bottom .btn-primary a {
    left: 0;
    right: auto;
    right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged .nav-item-search .overlay-wrapper {
    top: 11px;
  }
}
.tenant-ppad .c-header.sticky-header.header-pre-logged .nav--wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.sticky-header.header-pre-logged .nav--wrapper {
    margin-top: 0;
  }
}
.tenant-ppad .c-header .btn-subscribe {
  display: inline-block;
  margin-top: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .btn-subscribe {
    display: inline-block;
  }
}
.tenant-ppad .c-header .btn-subscribe.btn-primary a {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  letter-spacing: 0.22px;
  letter-spacing: 2px;
  font-weight: 700;
  width: auto;
  text-align: center;
  color: #fff;
  background-color: #002677;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  padding: 11px 34px;
  min-height: 44px;
  /* Medium devices (desktops, 1024px and up) */
  font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    padding: 11px 29px;
  }
  .tenant-ppad .c-header .btn-subscribe.btn-primary a:hover, .tenant-ppad .c-header .btn-subscribe.btn-primary a:focus {
    background: #ad841f;
    color: white;
    border: 0;
  }
}
.lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
  font-family: "JannaBold", "Open Sans", Arial, sans-serif;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem;
    letter-spacing: 0.22px;
    font-weight: bold;
    width: auto;
    text-align: center;
    color: #002677;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid #002677;
    padding: 11px 34px;
    min-height: 44px;
    /* Medium devices (desktops, 1024px and up) */
    font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 18px;
    line-height: 1.125rem;
    letter-spacing: 0.19px;
    font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
    font-weight: 700;
    opacity: 1;
  }
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    padding: 11px 29px;
  }
  .tenant-ppad .c-header .btn-subscribe.btn-primary a:hover, .tenant-ppad .c-header .btn-subscribe.btn-primary a:focus {
    background: #ad841f;
    color: white;
    border: 1px solid transparent;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .btn-subscribe.btn-primary a {
    font-family: "JannaBold", "Open Sans", Arial, sans-serif;
  }
}
.tenant-ppad .c-header .c-header-wrapper {
  position: relative;
}
.tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky {
  display: block;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  letter-spacing: 0.22px;
  letter-spacing: 2px;
  font-weight: 700;
  width: auto;
  text-align: center;
  color: #fff;
  background-color: #002677;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  padding: 11px 34px;
  min-height: 44px;
  /* Medium devices (desktops, 1024px and up) */
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 27px;
}
.lang-ar .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a {
    padding: 11px 29px;
  }
  .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a:hover, .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky a:focus {
    background: #ad841f;
    color: white;
    border: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-wrapper .btn-subscribe.sticky {
    display: none;
  }
}
.tenant-ppad .c-header .c-header-top {
  min-height: 100%;
  display: block;
  position: relative;
  z-index: 2;
  padding: 9px 0;
  width: 100%;
  padding-bottom: 6px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-top {
    padding: 0;
    border: 0;
    width: 50%;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-header .c-header-top {
    padding: 7px 0;
  }
}
.tenant-ppad .c-header .c-header-top-logo {
  display: inline-block;
  margin: 0 15px;
  width: auto;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-ppad .c-header .c-header-top-logo img {
  width: auto;
  height: 71px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-top-logo img {
    height: 118px;
    margin-top: -6px;
  }
}
.tenant-ppad .c-header .c-header-top-logo a:hover {
  opacity: 1;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-top-logo {
    margin: 0;
  }
}
.tenant-ppad .c-header .c-header-top-icon {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 24px;
  height: 34px;
  transform: translate(0, -50%);
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-header .c-header-top-icon {
    width: 29px;
    height: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-top-icon {
    position: relative;
  }
}
.tenant-ppad .c-header .c-header-top-icon.hamburger {
  right: 16px;
  filter: invert(100%);
  cursor: pointer;
}
.lang-ar .tenant-ppad .c-header .c-header-top-icon.hamburger {
  left: 16px;
  right: auto;
}
.tenant-ppad .c-header .c-header-top-icon.hamburger img {
  width: 18px;
  height: 12px;
}
.tenant-ppad .c-header .c-header-top-icon.close {
  /* Uses before by default. Uses after, when $isAfter is passed as true */
  right: 17px;
  top: 34px;
  font-size: 32px;
}
.tenant-ppad .c-header .c-header-top-icon.close:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .c-header-top-icon.close:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .c-header-top-icon.close:before {
  content: "\e905";
}
.lang-ar .tenant-ppad .c-header .c-header-top-icon.close {
  left: 17px;
  right: auto;
}
.lang-ar .tenant-ppad .c-header .c-header-top-icon.close {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-ppad .c-header .c-header-top-icon.close {
    top: 32px;
  }
}
.tenant-ppad .c-header .c-header-top-icon.close:before {
  color: #000;
}
.tenant-ppad .c-header .c-header-top-icon.close svg {
  display: none;
}
.tenant-ppad .c-header .c-header-top .profile {
  /* Uses before by default. Uses after, when $isAfter is passed as true */
  right: 67px;
  /* Small devices (tablets, 768px and up) */
}
.tenant-ppad .c-header .c-header-top .profile:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .c-header-top .profile:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .c-header-top .profile:before {
  content: "\e921";
}
.lang-ar .tenant-ppad .c-header .c-header-top .profile {
  left: 67px;
  right: auto;
}
@media (min-width: 768px) {
  .tenant-ppad .c-header .c-header-top .profile {
    height: 33px;
  }
}
.tenant-ppad .c-header .c-header-top .profile:before {
  position: absolute;
  font-size: 18px;
  bottom: 7px;
}
.lang-ar .tenant-ppad .c-header .c-header-top .profile {
  left: 69px;
}
.tenant-ppad .c-header .c-header-top .profile svg {
  display: none;
}
.tenant-ppad .c-header .c-header-top .whatsapp {
  display: inline-block;
  right: 95px;
  height: 20px;
  background-image: url("../../assets/img/whatsapp-header.svg");
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .c-header-top .whatsapp {
    display: none;
  }
}
.tenant-ppad .c-header .c-header-top .whatsapp svg {
  display: none;
}
.tenant-ppad .c-header .c-header-top .close {
  border: 0;
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile {
  /* Uses before by default. Uses after, when $isAfter is passed as true */
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile:before {
  content: "\e920";
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile:before {
  color: #002677;
}
.tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
  content: "";
  border: 1px solid #002677;
  border-left: 0;
  border-bottom: 0;
  position: absolute;
  width: 5px;
  height: 5px;
  margin-left: 4px;
  left: 21px;
  top: 15px;
  transform: translate(15px) rotate(135deg);
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
    top: 14px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
    left: 7px;
    top: 8px;
  }
}
.lang-ar .tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
  right: 55px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header.header-logged .c-header-top-icon.profile:after {
    right: 40px;
  }
}
.tenant-ppad .c-header.header-logged .profile-open .header-body {
  text-align: left;
  border: 1px solid #ddc798;
  border-bottom: none;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.header-logged .profile-open .header-body {
    border-top: none;
  }
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body {
  text-align: left;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged {
  margin-left: auto;
  margin-right: 22px;
  text-align: left;
  background-color: #fff;
  width: 200px;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged {
  margin-left: 22px;
  margin-right: auto;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged {
  text-align: right;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:before {
  right: 65px;
  content: "";
  position: absolute;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #ddc798;
  top: 77px;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:before {
  left: 65px;
  right: auto;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:after {
  right: 65px;
  content: "";
  position: absolute;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  top: 78px;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:after {
  left: 65px;
  right: auto;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile {
  margin: 0px 22px;
  border-bottom: 1px solid #f8ebd7;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile .header-body-profile-name {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
  line-height: 1.125rem;
  letter-spacing: 0.19px;
  color: #000;
  padding: 18px 0 10px;
  min-height: auto;
  border-bottom: none;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile .header-body-profile-name {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile .header-body-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile ul {
  margin: 0;
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile ul li {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
  line-height: 1.125rem;
  letter-spacing: 0.19px;
  font-weight: bold;
  padding: 0;
  border-top: none;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile ul li {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile ul li {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
.tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged .header-body-profile ul li a {
  color: #002677;
  padding-bottom: 6px;
  display: block;
}
.tenant-ppad .c-header.header-logged .profile-open .header-bottom {
  margin-left: auto;
  margin-right: 22px;
  display: block;
  min-height: auto;
  width: 200px;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-bottom {
  margin-left: 22px;
  margin-right: auto;
}
.tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout {
  text-align: left;
  background-color: #fff;
  border: solid #ddc798;
  border-width: 0 1px 1px 1px;
  min-height: auto;
  padding: 0px 22px;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout {
  text-align: right;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout {
    padding: 0;
    border: none;
  }
}
.tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout a {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 18px;
  line-height: 1.125rem;
  letter-spacing: 0.19px;
  color: #002677;
  font-weight: bold;
  padding: 6px 0 14px;
  margin: 0;
  border: none;
}
.lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout a {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-bottom.header-bottom-logged .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
.tenant-ppad .c-header.header-pre-logged .header-body {
  background: #fff;
  margin-top: -3px;
  border: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.header-pre-logged .header-body {
    display: block;
    width: 100%;
    background: transparent;
    margin-top: 0;
  }
}
.tenant-ppad .c-header.header-pre-logged .header-bottom {
  display: block;
  background: #fff;
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header.header-pre-logged .header-bottom {
    background: transparent;
    width: 56%;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-header.header-pre-logged .header-bottom {
    width: 36%;
  }
}
.tenant-ppad .c-header.header-logged {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-ppad .c-header.header-logged .header-body,
.tenant-ppad .c-header.header-logged .header-bottom {
    background: #fff;
    border: 0;
  }
}
.tenant-ppad .c-header .nav-item-search {
  top: 4px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .nav-item-search {
    background: transparent;
    width: 60%;
    margin: auto;
    min-height: 100px;
    height: auto;
    bottom: auto;
  }
}
.tenant-ppad .c-header .nav-item-search .overlay-container {
  height: 35px;
  width: 50%;
  margin: 0 auto;
}
.tenant-ppad .c-header .nav-item-search .overlay-wrapper {
  border: 1px solid #efdbb2;
  background: #fff;
  color: #002677;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .nav-item-search .overlay-wrapper {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
    max-width: 400px;
    min-height: 1px;
    margin: auto;
    position: relative;
    top: 26px;
  }
}
.tenant-ppad .c-header .nav-item-search .overlay-content-inner-container {
  left: 0;
  overflow-y: hidden;
  padding: 0;
}
.lang-ar .tenant-ppad .c-header .nav-item-search .overlay-content-inner-container {
  right: 0;
  left: auto;
  left: auto;
}
.tenant-ppad .c-header .nav-item-search .overlay-content-container {
  overflow: visible;
}
.tenant-ppad .c-header .nav-item-search .overlay--close-button {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .nav-item-search .overlay--close-button {
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
    border-left: 1px solid #e4e4e4;
    top: 53%;
    height: 16px;
    border-radius: 0;
  }
  .lang-ar .tenant-ppad .c-header .nav-item-search .overlay--close-button {
    left: 5px;
    right: auto;
  }
  .lang-ar .tenant-ppad .c-header .nav-item-search .overlay--close-button {
    border-right: 1px solid #e4e4e4;
  }
  .tenant-ppad .c-header .nav-item-search .overlay--close-button:before {
    font-size: 27px;
    line-height: 18px;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper {
  margin: 0;
  padding: 0;
  background: #fdf7ee;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .autocomplete-wrapper {
    padding: 0;
    background: #fff;
    width: 100%;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container {
  background: none;
  padding: 20px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .autocomplete-wrapper .input-container {
    padding: 10px 8px;
    border-bottom: none;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .input-placeholder {
  display: none;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  font-size: 1rem;
  line-height: 24px;
  line-height: 1.5rem;
  letter-spacing: 0.25px;
  padding-left: 29px;
  caret-color: #000;
  color: #000;
  opacity: 1;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
  font-family: "JannaRegular", "Open Sans", Arial, sans-serif;
  font-weight: normal;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
  padding-right: 29px;
  padding-left: 0;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-webkit-input-placeholder {
  color: #4a4a4a;
  opacity: 1;
  padding-left: 0;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-webkit-input-placeholder {
  padding-right: 0;
  padding-left: 0;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-moz-placeholder {
  color: #4a4a4a;
  opacity: 1;
  padding-left: 0;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-moz-placeholder {
  padding-right: 0;
  padding-left: 0;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-moz-placeholder {
  color: #4a4a4a;
  opacity: 1;
  padding-left: 0;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-moz-placeholder {
  padding-right: 0;
  padding-left: 0;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-ms-input-placeholder {
  color: #4a4a4a;
  opacity: 1;
  padding-left: 0;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-ms-input-placeholder {
  padding-right: 0;
  padding-left: 0;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::placeholder {
  color: #4a4a4a;
  opacity: 1;
  padding-left: 0;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::placeholder {
  padding-right: 0;
  padding-left: 0;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
    padding: 0 10px;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-webkit-input-placeholder {
    opacity: 0;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-moz-placeholder {
    opacity: 0;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::-moz-placeholder {
    opacity: 0;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input:-ms-input-placeholder {
    opacity: 0;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input::placeholder {
    opacity: 0;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset {
  right: 25px;
  /* Uses before by default. Uses after, when $isAfter is passed as true */
  height: 20px;
  width: 20px;
  color: #000;
  font-size: 21px;
  padding: 0;
  display: inline-block;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset {
  left: 25px;
  right: auto;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset:before {
  content: "\e905";
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset:before {
  line-height: 20px;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden {
  visibility: hidden;
  display: none;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden ~ .btn-submit {
  display: inline-block;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset ~ .btn-submit {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset {
    display: none;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden {
    display: none;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden ~ .btn-submit {
    display: inline-block;
  }
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-reset ~ .btn-submit {
    display: inline-block;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit {
  right: 17px;
  /* Uses before by default. Uses after, when $isAfter is passed as true */
  width: 18px;
  height: 25px;
  border: 0;
  padding: 0;
  opacity: 1;
  position: absolute;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit {
  left: 17px;
  right: auto;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #000;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:before {
  content: "\e910";
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit {
    right: 29px;
    height: 20px;
    background: transparent;
    position: relative;
    top: -2px;
  }
  .lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit {
    left: 29px;
    right: auto;
  }
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:hover, .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:focus {
  background: none;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit svg {
  display: none;
}
.tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit:before {
  font-size: 26px;
}
.tenant-ppad .c-header .hide-on-load .header-bottom {
  display: none;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .hide-on-load .header-bottom {
    display: block;
    padding: 21px 0px 0;
    text-align: right;
  }
  .lang-ar .tenant-ppad .c-header .hide-on-load .header-bottom {
    text-align: left;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-ppad .c-header .hide-on-load .header-bottom {
    padding: 36px 0px 0;
  }
}
.tenant-ppad .c-header .custom-arrow-icon span {
  background-color: #002677;
}
.tenant-ppad .c-header .opening-hours-dropdown {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .opening-hours-dropdown {
    display: inline-block;
    height: 45px;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container {
  color: #4A4A4A;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container {
    margin-top: 7px;
    margin-bottom: 0;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left svg {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left svg {
    width: 15px;
    height: 15px;
    padding: 0;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left h4 {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left h4 {
    font-size: 12px;
  }
  .lang-ar .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left h4 {
    font-size: 14px;
    margin-top: 0px;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details {
  font-size: 12px;
  line-height: 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details {
    margin-left: 31px;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details h4 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.5px;
  font-weight: 600;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details h4 {
    font-size: 12px;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
  font-weight: 700;
  text-decoration: none;
  color: #002677;
  letter-spacing: 0.22px !important;
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 20px;
  /* < 768px */
}
.lang-ar .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
  font-weight: 800;
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
    font-size: 0.75rem;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more::before {
  content: "";
}
.open .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .open .tenant-ppad .c-header .opening-hours-dropdown .opening-hours-container {
    padding: 7px 20px 20px 20px;
  }
}
.tenant-ppad .c-header .opening-hours-dropdown:not(.open) .opening-hours-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-ppad .c-header .opening-hours-dropdown:not(.open) .opening-hours-container {
    padding: 8px 20px;
  }
}
.tenant-ppad .c-header .c-header-wrapper .header-bottom-profile {
  overflow: visible;
}
.lang-ar .tenant-ppad .c-header .c-header-wrapper .header-bottom-profile .c-header-top-icon.profile::before {
  right: auto;
}
.tenant-ppad .c-header .c-header-wrapper .header-bottom-profile .c-header-top-icon.profile::after {
  left: auto;
  right: 0;
  top: -2px;
  bottom: 0;
  margin: auto;
  transform: rotate(135deg);
}
.lang-ar .tenant-ppad .c-header .c-header-wrapper .header-bottom-profile .c-header-top-icon.profile::after {
  left: 0;
  right: auto;
}
.lang-ar .tenant-ppad .c-header .c-header-wrapper .header-bottom .header-bottom-lang-switcher {
  margin-right: 5px;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top {
    padding: 24.5px 20px 21.5px 20px !important;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo img, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo img {
    width: auto;
    height: 71px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon {
    margin: 7px 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.hamburger, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.hamburger {
    filter: none;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close::before {
    content: none;
  }
  .lang-ar .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close, .lang-ar .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close {
    right: 0;
    left: auto;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close svg, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.close svg {
    display: block;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile {
    font-size: 25px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile::before {
    content: none;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile svg, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.profile svg {
    display: block;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.cart::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.cart::before {
    content: "\e903";
    font-family: "icon-font", sans-serif;
    position: absolute;
    font-size: 32px;
    top: 50%;
    transform: translateY(-50%);
    right: -6px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body {
    padding: 0;
    padding-top: 24px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher {
    padding-right: 15px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component {
    position: relative;
    inset: auto;
    transform: none;
    border: 0;
    padding: 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component::before {
    content: none;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option {
    margin: 0;
    font-size: 0.875rem;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option > span::after, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option > span::after {
    content: "\e907";
    font-family: "icon-font", sans-serif;
    position: absolute;
    font-size: 30px;
    left: 18px;
  }
  .lang-ar .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option > span::after, .lang-ar .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .selected-option > span::after {
    right: auto;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .dropdown-menu::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .dropdown-component .dropdown-menu::before {
    top: -6px;
    transform: rotate(225deg);
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .c-header-top-icon.cart, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .c-header-top-icon.cart {
    color: #000;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .c-header-top-icon.cart::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .c-header-top-icon.cart::before {
    content: "\e903";
    font-family: "icon-font", sans-serif;
    position: absolute;
    font-size: 40px;
    bottom: 0;
    left: -10px;
    top: 2px;
    transform: translateY(-50%);
    right: 0px;
    inset: 7;
    line-height: 1;
    height: 100%;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container input, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container input {
    padding-left: 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-submit, .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-reset, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-submit, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-reset {
    position: relative;
    min-width: 30px;
    height: 25px;
    right: 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-submit::before, .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-reset::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-submit::before, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .autocomplete-wrapper .input-container .btn-reset::before {
    font-size: 35px;
    line-height: 20px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content, .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content {
    padding: 22px 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .header-nav-link, .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .header-nav-link, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .header-nav-link, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .header-nav-link {
    font-weight: 600;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a {
    font-weight: 600;
    color: #002677;
    text-transform: uppercase;
    font-family: "AvenirNextDemiBold", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .lang-ar .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .lang-ar .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .lang-ar .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a, .lang-ar .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body.header-body-content-logged nav .nav--wrapper .nav--wrapper-content .meganav--text-wrapper li a {
    font-family: "JannaBold", "Open Sans", Arial, sans-serif;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper .header-bottom, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-bottom {
    width: 100%;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged {
    width: 100%;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile {
    margin: 0 37px;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile .header-body-profile-name, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile .header-body-profile-name {
    margin: 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile ul, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile ul {
    margin: 0;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile ul a, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-body-profile ul a {
    font-size: 0.875rem;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout {
    text-align: left;
    margin: 0 37px;
  }
  .lang-ar .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout, .lang-ar .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout {
    text-align: right;
  }
  .tenant-ppad .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout a, .tenant-ppad .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-body.header-body-logged .header-bottom-btn-logout a {
    border: 0;
    padding: 0;
    color: #002677;
    font-weight: 700;
    letter-spacing: 0.19px;
    font-size: 0.875rem;
    font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif !important;
    margin-top: 6px;
  }
  .tenant-ppad .custom-mobile-header .c-header.header-logged .c-header-wrapper .c-header-top .c-header-top-icon.profile::after, .tenant-ppad .custom-mobile-header.sticky-header .c-header.header-logged .c-header-wrapper .c-header-top .c-header-top-icon.profile::after {
    transform: rotate(135deg);
  }
}
.tenant-ppad #hamburger-cross-icon span {
  height: 2.2px;
  background: #404040;
}

.lang-ar .tenant-ppad .language-switcher .dropdown-item[data-value=EN] a {
  font-family: "AvenirNextRegular", "Open Sans", Arial, sans-serif !important;
  letter-spacing: 1.5px !important;
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
  padding-right: 15px;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .btn-submit {
    left: -12px;
  }
  .lang-ar .tenant-ppad .c-header .autocomplete-wrapper .input-container .form-input {
    padding-right: 0;
  }
}
.lang-ar .tenant-ppad .c-header .c-header-top-icon.whatsapp {
  right: auto;
}
.lang-ar .tenant-ppad .c-header .nav-item-search .overlay--close-button {
  border-left: 0;
}
.lang-ar .tenant-ppad .c-header .header-bottom .btn-subscribe.btn-primary a {
  padding: 12px 34px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-ppad .c-header .header-bottom .btn-subscribe.btn-primary a {
    padding: 11px 29px;
  }
}

.tenant-ppad {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-ppad .c-header.header-logged .profile-open .header-body {
    z-index: 8;
    overflow: visible;
  }
  .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged {
    margin-right: auto;
    text-align: left;
  }
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged {
    text-align: right;
  }
  .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:before {
    top: -9px;
    right: 37px;
  }
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:before {
    left: 23px;
    right: auto;
  }
  .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:after {
    right: 37px;
    top: -8px;
  }
  .lang-ar .tenant-ppad .c-header.header-logged .profile-open .header-body.header-body-logged:after {
    left: 23px;
    right: auto;
  }
  .tenant-ppad .c-header.header-logged .profile-open .header-body .header-bottom-btn-logout {
    width: auto;
  }
}